.birthday-holiday-section table {
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
	font-size: 13px;
}

.birthday-holiday-section th,
.birthday-holiday-section td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: center;
}

.birthday-holiday-section tbody tr:nth-child(odd) {
	background-color: #f9f9f9;
}

.birthday-holiday-section tbody tr:hover {
	background-color: #f1f1f1;
}

.birthday-holiday-section .progress-container {
	width: 100%;
	background-color: #ddd;
	border-radius: 5px;
	height: 15px;
	position: relative;
}

.birthday-holiday-section .progress-bar {
	height: 100%;
	border-radius: 5px;
	background-color: #4caf50;
}
