.timesheet-wrapper {
	width: 100%;
	background: #fff;
}

.timesheet-header {
	background: #fff;
	padding: 10px;
	padding-bottom: 0px;
}

.view-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.view-btn {
	padding: 6px 16px;
	margin-right: 5px;
	border: 1px solid #ddd;
	background: white;
	cursor: pointer;
	border-radius: 2px;
}

.view-btn:hover {
	background: #f5f5f5;
}

.date-picker {
	padding: 6px;
	border: 1px solid #ddd;
	border-radius: 2px;
	margin-left: auto;
}

.timesheet-title {
	background: #fc9a30; /* Change the background color to #fc9a30 */
	color: white; /* Text color remains white */
	padding: 12px;
	margin-top: 10px;
	text-align: left; /* Align the title text to the left */
	font-size: 1.8em; /* Optional: Increase font size for better visibility */
	font-weight: bold; /* Optional: Make the title bold */
}

/* Existing styles */
.timesheet-container {
	display: flex;
	padding: 0px 10px;
}

.calendar-section {
	flex: 2;
	border-right: 2px solid #ddd;
	padding-right: 20px;
}

.timesheet-form {
	flex: 1;
	background: #f8f9fa;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.timesheet-form h3 {
	color: #ff6f00;
	margin-bottom: 10px;
}

.timesheet-form select,
.timesheet-form input,
.timesheet-form textarea {
	width: 100%;
	margin-bottom: 10px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.timesheet-form textarea {
	height: 80px;
	resize: none;
}

.time-inputs {
	display: flex;
	gap: 10px;
}

.save-btn {
	background-color: #ff6f00;
	color: white;
	border: none;
	padding: 10px;
	width: 100%;
	cursor: pointer;
	border-radius: 4px;
	font-weight: bold;
}

.save-btn:hover {
	background-color: #e65c00;
}

.submit-btn {
	background-color: #ff6f00;
	color: white;
	border: none;
	padding: 10px;
	/* width: 100%; */
	cursor: pointer;
	border-radius: 4px;
	font-weight: bold;
}

.submit-btn:hover {
	background-color: #e65c00;
}

.description-scroll {
	max-height: 100px;
	overflow-y: auto;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background: #fff;
}
.timesheet-display {
	background: #fff;
	border-radius: 8px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	padding: 15px;
	margin-top: 5px;
	border: 1px solid #ddd;
}

.timesheet-entry {
	display: flex;
	justify-content: space-between;
	align-items: start;
	padding: 5px 0;
	font-size: 14px;
}

.timesheet-label {
	font-weight: 600;
	color: #333;
}

.timesheet-value {
	color: #666;
	text-align: right;
}

/* .timesheet-description {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0;
	font-size: 14px;
} */

.status-btn-saved {
    background-color: rgb(255, 0, 0);
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
    border-radius: 4px;
}

.status-btn {
    background-color: green;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
    border-radius: 4px;
}
.month-navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
  }
  
  .month-navigation button {
	background-color: #fc9a30;
	color: white;
	border: none;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 5px;
	cursor: pointer;
	transition: background 0.3s ease;
  }
  
  .month-navigation button:hover {
	background-color: #c76700;
  }
  
  .month-navigation button:active {
	transform: scale(0.95);
  }
  
  .month-display {
	font-size: 16px;
	font-weight: bold;
	color: #333;
  }
  
/* .fc-daygrid-day-frame{
	color: #fff;
    background-color: #fc9a30;
    padding: 10px !important;
    margin: 10px;
} */

.custom-calendar-cell {
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease;
}

.custom-calendar-cell:hover {
    background-color: #b2dfdb !important;
    cursor: pointer;
}

.custom-calendar-cell {
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease;
}

.custom-calendar-cell:hover {
    background-color: #b2dfdb !important;
    cursor: pointer;
}

