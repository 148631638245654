.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.faiconcover {
	border: 1px solid gray;
	padding: 4px;
	border-radius: 4px;
}

.tabulator .tabulator-header {
	background-color: #ffffff !important;
	border-bottom: 1px solid #999 !important;
}

.tabulator {
	border: 1px solid #ededed !important;
	background-color: #f7f7f7 !important;
}

.tabulator .tabulator-row .tabulator-cell {
	border-right: 1px solid #e9e9e9 !important;
}

.tabulator
	.tabulator-header
	.tabulator-headers
	.tabulator-col
	.tabulator-col-content {
	border-right: 1px solid #e9e9e9 !important;
}

.tabulator .tabulator-header .tabulator-headers .tabulator-col {
	border-right: 1px solid #e5e5e5 !important;
	background-color: #1e40af !important;
	color: white !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
	color: black !important;
}

.present {
	font-size: 11px;
	border: 1px solid green;
	padding: 5px 7px 5px 7px;
	border-radius: 33px;
	color: green;
	font-weight: 700;
}

.absent {
	font-size: 10px;
	border: 1px solid red;
	padding: 8px 8px 7px 6px;
	border-radius: 33px;
	color: red;
	font-weight: 700;
}

.month-picker > .rmp-container .rmp-popup.show {
	opacity: 1;
	width: fit-content !important;
	top: 32px !important;
	right: 8px !important;
}

.month-picker > .rmp-container .rmp-popup {
	transform: translate3d(0, 0, 0) !important;
	transition: none !important;
	padding: 0.4rem !important;
	height: 14.5rem !important;
}

.month-picker > .rmp-container {
	transform: translate3d(0, 0, 0) !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad li {
	width: 25% !important;
	font-size: 15px !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
	font-size: 15px !important;
	line-height: 43px !important;
	/* background: #5755d9 !important; */
	background: white;
	color: black !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
	line-height: 30px !important;
	/* color: white !important; */
	font-size: 15px !important;
	padding-top: 5px !important;
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
	height: 2.4rem !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad li {
	line-height: 49px !important;
	margin-top: 12px !important;
}

.month-picker > .rmp-container .rmp-popup.light {
	/* background-color: #565ad5 !important; */
	background-color: #fff !important;
	/* color: white!important; */
	height: 216px !important;
}

/* .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
  background: #5755d9;
 
} */
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
	background-color: rgb(87 85 217) !important;
	color: white;
}

.dialoag-container_2TwSP {
	padding: 7px 9px 16px 31px;
	display: inline-block;
	vertical-align: top;
}

.dialoag-container_2TwSP .title_1ce7i {
	border-left: rgb(87 85 217) solid 4px;
	padding-left: 6px;
	margin: 8px 0px;
	font-size: 20px;
	font-weight: bold;
}

.dialoag-container_2TwSP .content_3dQ6a {
	padding: 0 10px;
}

.dialoag-container_2TwSP .content_3dQ6a .key_2Wvze {
	color: rgb(87 85 217) !important;
	padding: 5px 0;
	display: inline-block;
	font-weight: bold;
	font-size: 14px;
}

.react-date-picker__wrapper {
	border: thin solid #c5c1c1 !important;
	border-radius: 4px !important;
}

.sm\:whitespace-normal {
	white-space: normal;
	text-align: initial;
}

.react-date-picker__inputGroup {
	font-size: 12px !important;
	padding: 5px !important;
}

.react-calendar {
	border: 1px solid #e3e3e3 !important;
	padding: 16px !important;
	font-size: 13px !important;
	border-radius: 4px !important;
	line-height: 16px !important;
}

.react-date-picker__calendar {
	width: 263px !important;
}

.tabulator-row .tabulator-cell {
	font-size: 12px !important;
}

.timelinetable .tabulator-row .tabulator-cell {
	height: 50px !important;
	padding: 0;
	padding-top: 10px;
	background: white !important;
}

.dark .timelinetable .tabulator-row .tabulator-cell {
	background: #232d44 !important;
	color: #fff !important;
}

.cardborder {
	border: 1px solid #f1eded;
	padding: 5px;
	margin: 8px;
}

.topright {
	position: absolute;
	right: 10px;
	top: 10px;
}

.bottomright {
	position: absolute;
	bottom: 10px;
	right: 5px;
	width: 28px;
}

.usericon {
	padding: 13px;
	background: #1e40af;
	color: white;
	border-radius: 24px;
	width: 40px;
	height: 40px;
}

.tabulator .tabulator-tableholder::-webkit-scrollbar {
	box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
	border-radius: 100px;
}

.tabulator .tabulator-tableholder::-webkit-scrollbar-track {
	background-color: rgb(255, 255, 255);
}

.tabulator .tabulator-tableholder::-webkit-scrollbar-thumb {
	border: 6px solid transparent;
	border-radius: 100px;
	background-color: #3c55a7;
	background-clip: content-box;
}

.tabulator .tabulator-footer {
	padding: 0px !important;
	border-top: none !important;
	background-color: #ffffff !important;
}

.tabulator .tabulator-footer {
	margin-top: 0rem !important;
}

.month-picker.holidaycalender > .rmp-container .rmp-popup.show {
	right: 6px !important;
}

.tblborder {
	/* border: 1px solid #cfd1d3; */
	box-shadow: 0px 0px 5px #cfd1d3;
	padding: 15px;
	border-radius: 5px;
}

.activeborder {
	border: 1px solid #1e40af;
	background: #d6dcef;
}

.activeborder1 {
	cursor: pointer;
}

::-webkit-scrollbar {
	width: 5px;
	display: none;
}

::-webkit-scrollbar-track {
	background-color: #1e40af;
	border-radius: 100px;
}

::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.section::-webkit-scrollbar-thumb {
	background-color: #d4aa70;
	border-radius: 100px;
}

.intro-x.cursor-pointer:hover {
	background: #496de7;
	color: white;
}

#app {
	border: 3px solid #e3e3e3;
	border-radius: 4px;
	padding: 20px;
}

#circle-avatar {
	display: grid;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #1e40af;
	place-items: center;
	border: 1px solid white;
}

.dropmenu {
	background: #1e40af;
	color: white;
	border-radius: 5px;
	padding: 0;
	width: 219px;
	text-align: left;
	margin-top: 35px;
}

@media screen and (max-width: 767px) {
	.month-picker > .rmp-container .rmp-overlay {
		background: none !important;
	}
}

.flex.items-center {
	font-size: 13px !important;
}

.loading {
	background: transparent
		url("https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif")
		center center no-repeat;
	background-size: 40px;
	background-position: center;
	/* background-size: cover; */
}

@media screen and (max-width: 2000px) {
	.basic-single {
		color: hsl(0, 0%, 40%);
		display: inline-block;
		font-size: 12;
		width: 170px;
		margin-top: 10px;
	}
}

@media screen and (min-width: 767px) {
	.basic-single {
		margin-top: 0px;
		width: 210px !important;
	}
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:active {
	background: #6165da !important;
	color: white !important;
	font-size: 12px !important;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad li {
	width: 23% !important;
	font-size: 11px !important;
	border: 1px solid #a3a3a378;
	margin: 3px !important;
}

.dark .month-picker > .rmp-container .rmp-popup.light {
	background-color: #232d45 !important;
}
.dark .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
	background-color: #232d45 !important;
	color: #fff !important;
}

.dark .css-1nmdiq5-menu,
.dark .css-13cymwt-control,
.dark .css-t3ipsp-control,
.dark .react-daterange-picker__wrapper {
	border-color: gray !important;
	background-color: #232d45 !important;
	color: #fff !important;
}
.dark .css-1dimb5e-singleValue {
	color: #fff !important;
}

.dark .react-daterange-picker__calendar .react-calendar {
	background: #232d45 !important;
	color: white !important;
}

.dark .react-date-picker__calendar .react-calendar {
	background: #232d45 !important;
	color: white !important;
}

@import url(https://fonts.googleapis.com/css?family=Lato:400,400italic,700);

.pad {
	width: 350px;
	height: 495px;
	background-color: #2499ee;
	/* background-color: #232323; */
	/* position: absolute; */
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	box-shadow: 0 21px 29px -32px black;
	animation: showPad 1s ease forwards 1;
	border-radius: 10px !important;
}
.pad .dial-pad .contact {
	width: 50%;
	position: relative;
	/* margin-left: 20%; */
	/* margin-top: 40px; */
	opacity: 0;
	transition: opacity 0.5s ease;
}
.pad .dial-pad .contact.showContact {
	opacity: 1;
}
.pad .dial-pad .contact .avatar {
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center center;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	box-shadow: 0 15px 30px -10px black;
	position: absolute;
	left: 0px;
	top: 8px;
}
.pad .dial-pad .contact .contact-info {
	border-radius: 8px;
	width: 85%;
	margin-left: 15%;
	background-color: #2d2d2d;
	height: 76px;
	overflow: hidden;
}
.pad .dial-pad .contact .contact-info > div {
	width: 80%;
	margin-left: 20%;
	font-size: 12px;
	margin-top: 3px;
}
.pad .dial-pad .contact .contact-info .contact-name {
	color: #fdfdfd;
	margin-top: 12px;
}
.pad .dial-pad .contact .contact-info .contact-position {
	font-style: italic;
	color: #aeaeae;
}
.pad .dial-pad .contact .contact-info .contact-number {
	color: white;
}
.pad .dial-pad .contact .contact-info .contact-number span {
	color: #3de066;
	display: inline;
}
.pad .dial-pad .contact .contact-buttons {
	position: absolute;
	right: -5px;
	top: 0px;
	width: 40px;
	height: 76px;
}
.pad .dial-pad .contact .contact-buttons button {
	border: none;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	box-shadow: 0 12px 25px -5px black;
	display: block;
	position: absolute;
	right: 0px;
	background-size: 75% auto;
	background-position: center center;
	background-repeat: no-repeat;
}
.pad .dial-pad .contact .contact-buttons button:focus {
	outline: none;
}
.pad .dial-pad .contact .contact-buttons button.icon-message {
	background-color: #ffc44e;
	top: 5px;
}
.pad .dial-pad .contact .contact-buttons button.icon-video {
	background-color: #a529f9;
	bottom: 5px;
}
.pad .dial-pad .phoneString {
	width: 100%;
	height: 66px;
	background-color: #279bea;
	padding: 10px;
	padding-top: 15px;
	border: 1px solid #2692d7;
	border-radius: 10px;
}

.pad .dial-pad .phoneString input {
	background-color: transparent;
	/* width: 60%; */
	/* margin-left: 20%; */
	/* height: 0px; */
	border: none;
	font-size: 22px;
	color: white;
	font-weight: 700;
	letter-spacing: 2px;
}
span#inputGroup-sizing-sm {
	background: transparent;
	border: none;
	width: 61px;
	margin-top: 9px;
}
.pad .dial-pad .phoneString input:focus {
	outline: none;
	--tw-ring-shadow: none;
}
.pad .dial-pad .digits {
	overflow: hidden;
	width: 59%;
	margin-left: 20%;
	margin-top: 21px;
}
.pad .dial-pad .digitshangup {
	overflow: hidden;
	width: 59%;
	margin-left: 20%;
	margin-top: 254px;
	/* margin-top: 10px; */
}
.pad .dial-pad .digits .dig-spacer {
	width: 30px;
	margin: 10px calc(50% - 90px);
	float: left;
}
.pad .dial-pad .digits .dig {
	color: white;
	font-size: 22px;
	float: left;
	/* background-color: #2d2d2d; */
	background-color: #2499ee;
	text-align: center;
	width: 56px;
	height: 56px;
	border-radius: 100%;
	margin: 10px 0px;
	padding-top: 4px;
	font-weight: 700;
	cursor: pointer;
	padding-top: 14px !important;
	border: 1px solid #78bdeb;
}
.pad .dial-pad .digits .dig.clicked {
	animation: pulse-gray linear 0.5s 1;
}
.pad .dial-pad .digits .dig:nth-child(3n-1) {
	margin: 10px calc(50% - 90px);
}
.pad .dial-pad .digits .dig.astrisk {
	padding-top: 20px !important;
	height: 53px;
}
.pad .dial-pad .digits .dig.pound {
	padding-top: 10px;
	height: 55px;
}
.pad .dial-pad .digits .dig .sub-dig {
	font-size: 8px;
	font-weight: 300;
	position: relative;
	top: -2px;
}
.pad .dial-pad .digits .dig.addPerson,
.pad .dial-pad .digits .dig.goBack {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 55% auto;
	margin-bottom: 25px;
	/* box-shadow: 0px 25px 30px -15px black; */
	box-shadow: 0px 25px 30px -15px #537085;
}
.pad .dial-pad .digits .dig.addPerson {
	background-color: #208320;
	/* background-color: #285efa; */
	/* background-image: url(https://s16.postimg.org/4u2rbu85t/add_Person.png); */
}
.pad .dial-pad .digits .dig.addPerson.clicked {
	animation: pulse-blue linear 0.5s 1;
}
.pad .dial-pad .digits .dig.goBack {
	background-color: #fa4a5d;
	/* background-image: url(https://s4.postimg.org/x6g6auu7d/back_Arrow.png); */
}
.pad .dial-pad .digits .dig.goBack.clicked {
	animation: pulse-red linear 0.5s 1;
}
.pad .call-pad {
	opacity: 0;
	height: 0px;
	pointer-events: none;
	/* background-image: url(https://s21.postimg.org/x4te7wpo7/call_Background.png); */
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	transition: opacity 0.3s ease;
	position: absolute;
	width: 100%;
	left: 0px;
	top: 0px;
	transition: opacity 0.3s ease;
}
.pad .call-pad.in-call {
	height: 100%;
	opacity: 1;
	pointer-events: all;
}
.pad .call-pad .pulsate {
	opacity: 0;
	width: 150px;
	height: 0px;
	overflow: visible;
	position: relative;
	display: block;
	margin: 0 auto 0;
	top: 120px;
	transition: opacity 0.5s ease;
}
.pad .call-pad .pulsate.active-call {
	animation: pulsator 2s ease infinite;
	opacity: 1;
}
.pad .call-pad .pulsate div {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.06);
	border-radius: 100%;
	margin: auto;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}
.pad .call-pad .pulsate div:nth-child(1) {
	width: 110px;
	height: 110px;
}
.pad .call-pad .pulsate div:nth-child(2) {
	width: 122px;
	height: 122px;
}
.pad .call-pad .pulsate div:nth-child(3) {
	width: 134px;
	height: 134px;
}
.pad .call-pad .ca-avatar {
	width: 100px;
	height: 100px;
	margin: 70px auto;
	margin-bottom: 30px;
	display: block;
	background-color: #111;
	border-radius: 100%;
	box-shadow: 0px 20px 25px -10px rgba(0, 0, 0, 0.8);
	background-position: center center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	transition:
		opacity 1s ease,
		transform 1s ease;
	opacity: 0.5;
	transform: scale(0.5, 0.5);
}
.pad .call-pad .ca-avatar.in-call {
	transform: scale(1, 1);
	opacity: 1;
}
.pad .call-pad .ca-name,
.pad .call-pad .ca-number,
.pad .call-pad .ca-status {
	width: 60%;
	margin-left: 20%;
	color: white;
	text-align: center;
	font-weight: bold;
	margin-bottom: 15px;
}
.pad .call-pad .ca-name {
	font-size: 18px;
}
.pad .call-pad .ca-number {
	font-size: 28px;
	letter-spacing: 2px;
}
.pad .call-pad .ca-status {
	font-size: 30px;
	margin-top: 40px;
	margin-bottom: 40px;
}
.pad .call-pad .ca-status:after {
	content: attr(data-dots);
	position: absolute;
}
.pad .call-pad .ca-buttons {
	width: 70%;
	margin-left: 15%;
}
.pad .call-pad .ca-buttons .ca-b-single {
	float: left;
	width: 60px;
	height: 60px;
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 100%;
	position: relative;
	margin-bottom: 40px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 55% auto;
}
.pad .call-pad .ca-buttons .ca-b-single:nth-child(3n-1) {
	margin-left: calc(100% - 230px);
	margin-right: calc(100% - 230px);
}
.pad .call-pad .ca-buttons .ca-b-single:after {
	content: attr(data-label);
	color: white;
	position: absolute;
	text-align: center;
	font-size: 10px;
	width: 100px;
	bottom: -20px;
	left: -18px;
	letter-spacing: 2px;
}
.call {
	color: white;
	font-size: 30px;
	text-align: center;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	margin: 10px 0px;
	font-weight: 700;
	cursor: pointer;
	position: absolute;
	left: calc(50% - 30px);
	bottom: 25px;
	box-shadow: 0px 25px 30px -15px black;
	background-color: #3de066;
}
.call .call-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-size: 60% auto;
	background-repeat: no-repeat;
	background-position: center center;
	/* background-image: url(https://s13.postimg.org/sqno4q8sj/call.png); */
	transition: transform 0.3s ease;
}
.call .call-icon.in-call {
	-ms-transform: rotate(134deg);
	-webkit-transform: rotate(134deg);
	transform: rotate(134deg);
}
.call .call-change {
	width: 60px;
	height: 60px;
	border-radius: 100%;
	overflow: hidden;
}
.call .call-change span {
	width: 70px;
	height: 67px;
	display: block;
	background-color: #fa4a5d;
	position: relative;
	top: 70px;
	left: 70px;
	border-radius: 100%;
	transition:
		left 0.3s ease,
		top 0.3s ease;
}
.call .call-change.in-call span {
	top: -5px;
	left: -5px;
}
.call.clicked {
	animation: pulse-green linear 0.5s 1 forwards;
}
@keyframes pulse-gray {
	0% {
		box-shadow:
			inset 0 0 0px 30px #2d2d2d,
			inset 0 0 0px 30px white;
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	10% {
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	30% {
		box-shadow:
			inset 0 0 0px 10px #2d2d2d,
			inset 0 0 0px 30px white;
	}
	60% {
		box-shadow:
			inset 0 0 0px 0px #2d2d2d,
			inset 0 0 0px 0px white;
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}
@keyframes pulse-blue {
	0% {
		box-shadow:
			inset 0 0 0px 30px #285efa,
			inset 0 0 0px 30px white;
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	10% {
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	30% {
		box-shadow:
			inset 0 0 0px 10px #285efa,
			inset 0 0 0px 30px white;
	}
	60% {
		box-shadow:
			inset 0 0 0px 0px #285efa,
			inset 0 0 0px 0px white;
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}
@keyframes pulse-green {
	0% {
		box-shadow:
			inset 0 0 0px 30px #3de066,
			inset 0 0 0px 30px white;
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	10% {
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	30% {
		box-shadow:
			inset 0 0 0px 10px #3de066,
			inset 0 0 0px 30px white;
	}
	60% {
		box-shadow:
			inset 0 0 0px 0px #3de066,
			inset 0 0 0px 0px white;
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}
@keyframes pulse-red {
	0% {
		box-shadow:
			inset 0 0 0px 30px #fa4a5d,
			inset 0 0 0px 30px white;
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	10% {
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	30% {
		box-shadow:
			inset 0 0 0px 10px #fa4a5d,
			inset 0 0 0px 30px white;
	}
	60% {
		box-shadow:
			inset 0 0 0px 0px #fa4a5d,
			inset 0 0 0px 0px white;
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}
@keyframes pulsator {
	0% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	40% {
		-ms-transform: scale(0.8, 0.8);
		-webkit-transform: scale(0.8, 0.8);
		transform: scale(0.8, 0.8);
	}
	100% {
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}
@keyframes showPad {
	0% {
		top: 50px;
		opacity: 0;
	}
	100% {
		top: 0px;
		opacity: 1;
	}
}
p {
	position: fixed;
	bottom: 0px;
	left: 15px;
	color: white;
	font-family: Lato;
	font-weight: 300;
	overflow: hidden;
}
p a:link,
p a:visited {
	color: white;
}
p a:hover {
	opacity: 0.5;
}
p img {
	width: 20px;
	height: 20px;
	position: relative;
	top: 6px;
}

.d-none {
	display: none;
}

.react-tel-input .flag-dropdown.open .selected-flag {
	background: transparent !important;
	border: none !important;
}

.react-tel-input .flag-dropdown {
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	background-color: transparent !important;
	border: none !important;
	/* font-size: 42px!important; */
}

.react-tel-input .country-list {
	background-color: #279bea !important;
	text-align: left !important;
	color: white !important;
}
.react-tel-input .country-list .country .dial-code {
	color: #d1d1d1 !important;
}

.react-tel-input .country-list .country.highlight {
	background-color: #3390df !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
	background-color: #279bea !important;
}
.react-tel-input .country-list .country:hover {
	background-color: #3592dc !important;
}

.achievement-container {
	display: flex;
	border-radius: 10px;
	background-color: white;
	margin-top: 4px;
	padding: 5px;
}
.achievement-text-container {
	background-color: var(--BG-MAIN-light, #f8f9fb) !important;
	margin: 7px;
	padding: 10px;
	border-radius: 10px;
	width: 100%;
	text-align: justify;
}
.achievement-goal-container {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	width: 43%;
	text-align: justify;
}
.achievement-title {
	color: gray;
	font-weight: bold;
	text-align: justify;
	font-size: medium;
	/* padding: 5px; */
}
.goal-content {
	padding: 5px;
}
.hangupdiv {
	width: 34%;
	height: 71px;
	margin-left: 77px;
	background: rgb(245 90 90);
	cursor: pointer;
	border-radius: 50px;
	padding-top: 11px;
	color: white;
	font-size: 27px;
}
.phoneString input.form-control {
	box-shadow: none;
}

.headercallincoming {
	background: #208320;
	border: 1px solid #208320;
	border-radius: 72px;
	padding: 14px 5px 4px 4px;
	width: 40px;
	height: 40px;
	margin-right: 5px;
	margin-left: 5px;
}

.headercallhangup {
	background: #c81616;
	border: 1px solid #c81616;
	border-radius: 21px;
	padding: 14px 5px 4px 4px;
	width: 40px;
	transform: rotate(225deg);
	height: 40px;
}
.headercallno {
	margin-right: 5px;
	margin-left: 5px;
	padding: 0px 39px 6px 34px;
	border-right: 1px solid #ad9696;
}
.headerfont {
	font-size: 15px;
}

.rmp > .rmp-container .rmp-popup.show {
	top: 0px !important;
	right: -116px !important;
}

.tw-panel-with-bg {
	margin: 4px;
}

.tw-flex-row {
	display: flex;
	background-color: rgb(190, 190, 190);
	padding: 5px;
	background-color: white !important;
}

.badge-image {
	height: 50px;
	width: 50px;
}
.badge-container img {
	background-color: var(--BG-MAIN-light, #f8f9fb) !important;
	border-radius: 4px;
	padding: 4px;
	margin: 4px;

	/* transition: border-color 0.3s ease; Add transition for smooth effect */
}
.badge-container img:hover {
	border: 4px solid #ffd25e; /* Add border when hovered */
	padding: 5px;
}
.badge-header {
	background-color: white !important;
	display: flex;
	justify-content: space-between;
	padding: 2px;
	margin: 4px;
	border-radius: 10px;
	margin-bottom: 10px;
}

.tw-panel-with-bg {
	border-radius: 10px;
	padding: 4px;
	border-radius: 10px;
	background-color: white;
}

.badge-header-container {
	padding: 6px;
}
.badge-header-section {
	display: flex;

	padding: 4px;
}
.badge-input {
	outline: none !important;
	outline: 0;
	border-width: 0 0 2px;
	border-color: blue;
	color: none;
	margin-left: 10px;
	width: 300px;
	height: 90%;
	transition: all 1s ease-in-out;
}
.badge-input input ::focus {
	outline: none;
	border-color: rgb(55, 203, 230);
}
.tw-pseudo-label {
	padding: 10px;
	display: flex;
	font-size: 15px;
	font-weight: bold;
	background-color: white !important;
}

.badge-delete-icon {
	background-color: white;
	padding: 7px;
	border-radius: 4px;
	border: 1px solid black;
}

.ReactModal__Overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

.modal-custom {
	background-color: whitesmoke;

	background-image: url("https://jenya.trackabi.com/img/congrats-popup/congrats.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom center;

	display: flex;
}

.pop_bg_img {
	background-image: url("https://jenya.trackabi.com/img/congrats-popup/confetti.svg");
	background-position: top center;
	/* background-size: 200px; */
	padding: 10px;
	background-repeat: no-repeat;
	background-position: top center;
}

.pop_container {
	padding: 12%;
	margin: 150px;

	background-size: 200px;
	background-image: url("https://jenya.trackabi.com/img/congrats-popup/frame.svg");
	background-repeat: no-repeat;
	background-position: top center;
}
.custom-form-selection {
	width: 50%;
}
.custom-input {
	width: 50%;
}
.Selected_img {
	height: 50%;
	width: 50%;
	position: relative;
	left: 60px;
	bottom: 50px;
}
.goal-least {
	padding: 10px;
	background-color: var(--BG-MAIN-light, #f8f9fb) !important;
}

.selected {
	border: 4px solid #ffd25e;
	padding: 5px;
}
.achivements-container {
	display: flex;
	/* padding: 10px; */
}
/* .sidebar {
	height: 85vh;
	overflow-y: auto;
} */

.weekOffColor {
	background-color: rgb(253, 145, 212);
}

.danger {
	background-color: rgb(255, 108, 108);
}

.monthly-report-header {
	background-color: #4682b4;
}

.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 8px 10px !important;
	background-color: white !important;
}

.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
	background-color: white !important;
}

.custom-datepicker {
	width: max-content;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-content {
	background: white;
	padding: 0px;
	border-radius: 8px;
	width: 100%;
	position: relative;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.close-button {
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
}

.modal-input {
	width: 100%;
	border-radius: 3px;
	border: 1px solid #00000075;
}

.modal-button-new {
	background-color: #24a184;
	width: 100%;
	padding: 10px;
	color: #fff;
	border-radius: 4px;
	/* margin-top: 10px; */
}

.add-emp-btn {
	background-color: #24a184;
	color: #fff;
	font-size: 14px;
	padding: 8px;
	border-radius: 4px;
}

.table-outlineBorder {
	border: 1px solid #fc9a30;
}

/* new employee table css */

.employee-table td,
.employee-table th {
	text-align: left;
}

.employee-table-container {
	max-height: 100vh; /* Set the desired height for scrolling */
	overflow-y: auto; /* Enable vertical scrolling */
	border: 1px solid #ddd; /* Optional border around the table */
}

/* Ensuring the table takes full width */
.employee-table {
	width: 100%;
	border-collapse: collapse;
	font-family: Arial, sans-serif;
	font-size: 14px;
	color: #333;
}

/* Fix the header when scrolling */
.employee-table thead th {
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 2;
	text-align: left;
	font-weight: bold;
}

/* Table cell styling */
.employee-table th,
.employee-table td {
	padding: 12px 15px;
	border: 1px solid #ddd;
	margin: 10px 0px !important;
	background-color: #fff; /* White background for cells */
}

/* Table row colors - Even rows have standard white background */
.employee-table tbody tr:nth-child(even) {
	background-color: #fff; /* Ensure even rows are white */
}

/* Hover effect for rows - Subtle light gray background on hover */
.employee-table tbody tr:hover {
	background-color: #f8f9fa; /* Light gray color for hover effect */
}

/* Active and Inactive status styles */
.employee-table .status-active {
	color: #28a745;
	font-weight: bold;
}

.employee-table .status-inactive {
	color: #dc3545;
	font-weight: bold;
}

/* Contact icons */
.employee-table .contact-icons {
	display: flex;
	gap: 10px;
	align-items: center;
}

.employee-table .contact-icons img {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

/* Action icons */
.employee-table .actions {
	display: flex;
	gap: 15px;
	justify-content: center;
}

.employee-table .actions i {
	cursor: pointer;
	font-size: 18px;
	color: gray;
	transition: color 0.3s ease;
}

.employee-table .actions i:hover {
	color: #fc993036;
}

/* old emloyee table */
/* .employee-table td,
.employee-table th {
	text-align: left;
}

.employee-table-container {
	max-height: 100vh; 
	overflow-y: auto; 
	border: 1px solid #ddd; 
}

.employee-table {
	width: 100%;
	border-collapse: collapse;
	font-family: Arial, sans-serif;
	font-size: 14px;
	color: #333;
}

.employee-table thead th {
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 2;
	text-align: left;
	font-weight: bold;
}

.employee-table th,
.employee-table td {
	padding: 12px 15px;
	border: 1px solid #ddd;
	margin: 10px 0px !important;
}

.employee-table tbody tr:nth-child(even) {
	background-color: #fff;
}

.employee-table tbody tr:hover {
	background-color: #fff;
}

.employee-table .status-active {
	color: #28a745;
	font-weight: bold;
}

.employee-table .status-inactive {
	color: #dc3545;
	font-weight: bold;
}

.employee-table .contact-icons {
	display: flex;
	gap: 10px;
	align-items: center;
}

.employee-table .contact-icons img {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.employee-table .actions {
	display: flex;
	gap: 15px;
	justify-content: center;
}

.employee-table .actions i {
	cursor: pointer;
	font-size: 18px;
	color: gray;
	transition: color 0.3s ease;
}

.employee-table .actions i:hover {
	color: #fc993036;
} */

.icon-container {
	display: flex;
	gap: 10px;
	position: relative;
}

.icon {
	position: relative;
	cursor: pointer;
}

.icon:hover::after {
	content: attr(data-tooltip);
	position: absolute;
	bottom: -40px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fc9a30;
	color: #fff;
	padding: 8px 12px;
	border-radius: 4px;
	white-space: nowrap;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	font-size: 14px;
	z-index: 10;
}

.icon:hover::before {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	border-width: 8px;
	border-style: solid;
	border-color: #fc9a30 transparent transparent transparent;
}
.custom-tab {
	text-transform: none;
	min-width: 100px;
	color: #000;
	background-color: #fff;
	padding: 8px 12px;
	border: 1px solid rgba(0, 0, 0, 0.17);
	margin: 5px 0;
	border-radius: 0;
}
.custom-tab:first-of-type {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}
.custom-tab:last-of-type {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}
.custom-tab.active {
	background-color: #38b2ac !important;
	color: #fff;
}
/* Base style for all options */
.category-select option {
	background: #fff;
	color: #000;
	transition:
		background 0.3s ease,
		color 0.3s ease;
}

/* Style for the hover state */
.category-select option:hover {
	background: #fc9a30;
	color: #fff;
}

/* Style for the selected state */
.category-select option:checked {
	background: #fc9a30;
	color: #fff;
}

/* Default option (Select Category, Loading, etc.) */
.default-option {
	background: #fc9a30;
	color: #fff;
}

.sidebar-menu .side-menu--active {
	border-left: 4px solid #fc9a30 !important;
}
.sidebar-menu .side-menu--active::before {
	content: "";
	position: absolute;
	left: 0;
	top: 10%;
	height: 80%;
	width: 4px;
	background-color: #fc9a30 !important;
	border-radius: 2px;
}

/* 29-1 */
.react-date-picker__inputGroup input {
	/* color: white !important; */
	background-color: transparent !important;
	padding: 0px !important;
}

.react-date-picker__wrapper {
	border: thin solid #c5c1c1 !important;
	border-radius: 4px !important;
	height: 30px !important;
	margin-top: 4px !important;
}

.custom-swal-popup {
	font-family: "Arial", sans-serif;
	border-radius: 10px;
	padding: 10px;
	width: 450px;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.custom-swal-title {
	font-size: 15px;
	font-weight: bold;
	color: #333;
	/* margin-bottom: 1px; */
	margin-bottom: -25px !important;
}

.custom-swal-html {
	font-size: 16px;
	color: #555;
	margin: 10px 0;
}

.custom-swal-confirm-btn {
	background-color: #28a745;
	color: white;
	font-size: 16px;
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
}

.custom-swal-confirm-btn:hover {
	background-color: #218838;
}

.custom-swal-cancel-btn {
	background-color: transparent;
	color: #dc3545;
	font-size: 16px;
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
}

.custom-swal-cancel-btn:hover {
	text-decoration: underline;
}
.custom-swal-popup {
	font-family: "Arial", sans-serif;
	border-radius: 10px;
	padding: 5px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.custom-swal-title {
	font-size: 20px;
	font-weight: bold;
	color: #333;
	margin-bottom: 5px;
}

.custom-swal-html {
	font-size: 16px;
	color: #555;
	margin: 0; /* Remove extra margin */
}

.custom-swal-confirm-btn {
	background-color: #28a745;
	color: white;
	font-size: 16px;
	padding: 10px 120px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	width: 100%;
}

.custom-swal-confirm-btn:hover {
	background-color: #218838;
}

.custom-swal-cancel-btn {
	background-color: transparent;
	color: #dc3545;
	font-size: 16px;
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	width: 100%;
	margin-top: 10px;
	text-align: center;
}

.custom-swal-cancel-btn:hover {
	color: #c82333;
}
tr.expanded-row {
	/* background-color: #f9fafb; */
}

.expanded-content {
	padding: 10px;
	/* background-color: white; */
	border: 1px solid #e5e7eb;
	border-radius: 8px;
}
.modal-button-submit {
	background-color: #0053ff;
	color: white;
	padding: 5px;
	border-radius: 5px;
}

.modal-button-close {
	background-color: #fb7474;
	color: white;
	padding: 5px;
	border-radius: 5px;
}

.modal-table td,
.modal-table th {
	text-align: left;
}

.modal-table-container {
	max-height: 100vh; /* Set the desired height for scrolling */
	overflow-y: auto; /* Enable vertical scrolling */
	border: 1px solid #ddd; /* Optional border around the table */
}

/* Ensuring the table takes full width */
.modal-table {
	width: 100%;
	border-collapse: collapse;
	font-family: Arial, sans-serif;
	font-size: 14px;
	color: #333;
}

/* Fix the header when scrolling */
.modal-table thead th {
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 2;
	text-align: left;
	font-weight: bold;
}

/* Table cell styling */
.modal-table th,
.modal-table td {
	padding: 5px;
	border: 1px solid #ddd;
	margin: 10px 0px !important;
	background-color: #fff; /* White background for cells */
}

/* Table row colors - Even rows have standard white background */
.modal-table tbody tr:nth-child(even) {
	background-color: #fff; /* Ensure even rows are white */
}

/* Hover effect for rows - Subtle light gray background on hover */
.modal-table tbody tr:hover {
	background-color: #f8f9fa; /* Light gray color for hover effect */
}

/* Active and Inactive status styles */
.modal-table .status-active {
	color: #28a745;
	font-weight: bold;
}

.modal-table .status-inactive {
	color: #dc3545;
	font-weight: bold;
}

/* Contact icons */
.modal-table .contact-icons {
	display: flex;
	gap: 10px;
	align-items: center;
}

.modal-table .contact-icons img {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

/* Action icons */
.modal-table .actions {
	display: flex;
	gap: 15px;
	justify-content: center;
}

.modal-table .actions i {
	cursor: pointer;
	font-size: 18px;
	color: gray;
	transition: color 0.3s ease;
}

.modal-table .actions i:hover {
	color: #fc993036;
}

.nav nav-tabs {
	border: none !important;
}

.box-counts {
	padding: 10px;
	border: 1px solid #c2c2c2;
	border-radius: 5px;
}

/* styles.css */
.attendance-box {
	padding: 10px; /* Equivalent to Tailwind's p-4 */
	margin: 2px;
	border-radius: 5px; /* rounded-lg */
	border: 1px solid #c2c2c2;

	/* font-size: 18px; */
	font-weight: bold;
}

.attendance-box span {
	margin-right: 10px;
}

.attendance-container {
	display: grid;
	grid-template-columns: 1fr;
	/* Equivalent to Tailwind's gap-4 */
}

@media (min-width: 768px) {
	.attendance-container {
		grid-template-columns: 1fr 1fr; /* Equivalent to md:grid-cols-2 */
	}
}
.profile-info {
	min-height: "700px";
}

.first-tab-active {
	background-color: #fc9a30 !important;
	color: #fff !important;
	padding: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
	border: 1px solid #383838 !important;
}

.first-tab {
	background-color: #ffffff !important;
	color: #000000 !important;
	padding: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
	border: 1px solid #383838;
}

.middle-tab-active {
	background-color: #fc9a30 !important;
	color: #fff !important;
	padding: 5px !important;
	border: 1px solid #383838 !important;
}

.middle-tab {
	background-color: #ffffff !important;
	color: #000000 !important;
	padding: 5px !important;
	border: 1px solid #383838;
}

.last-tab-active {
	background-color: #fc9a30 !important;
	color: #fff !important;
	padding: 5px !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border: 1px solid #383838 !important;
}

.last-tab {
	background-color: #ffffff !important;
	color: #000000 !important;
	padding: 5px !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border: 1px solid #383838;
}

.profile-cancle {
	background-color: #c4c4c4;
	padding: 10px;
	color: #fff;
	border-radius: 5px;
	font-size: 15px;
	cursor: pointer;
	margin-top: 20px;
}

.profile-next {
	background-color: #fc9a30;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	font-size: 15px;
	cursor: pointer;
	margin-top: 20px;
}
