body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
}

.calendaricon {
  border: 1px solid #ced4da;
  padding: 5px;
  padding-top: 8px;
}

.calenderdate {
  width: 115px;
  height: 36px;
}

.dark .tabulator .tabulator-table .tabulator-row {
  background: #3a4768;
  color: white;
}
.dark .tabulator .tabulator-footer .tabulator-paginator {
  color: #555;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  background: #232d44;
}



.activebg {
  background-color: #9ea4a9;
}

.dark .wrapper-grey.pt-1{
  background: #4d5c7e;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  padding: 1.8em 3em 25px!important; 
  line-height: 30px!important; 
}